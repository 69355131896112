<template>
  <div>
    <b-card>
      <title-text />
    </b-card>
    <image-card />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

import TitleText from '@/views/Admin/Esg_items/elements/Title.vue'
import ImageCard from '@/views/Admin/Esg_items/elements/Image.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    ImageCard,
  },
  computed: {
    itemData() {
      return this.$store.getters['esgItems/dataItem']
    },
  },
}
</script>
